import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, mergeMap, switchMap, finalize, mapTo } from 'rxjs/operators';
import { UserModel } from '../_models/user.model';
import { AuthModel } from '../_models/auth.model';
import { AuthHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { AppInitService } from '../../../app-init/app-init.service';
import { isNullishCoalesce } from 'typescript';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private isLoadingSubject: BehaviorSubject<boolean>;
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  private userLocalStorageToken = '';
  // public fields
  currentUser$: Observable<UserModel>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserModel>;
  public isRefreshToken = false;
  subdomainSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    undefined
  );
  layoutThemeSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    undefined
  );
  layoutThemeSubject$: Observable<any>;

  subdomainSubject$:  Observable<any>;

  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  get subdomainValue() : String {
    return this.subdomainSubject.value;
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router,
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.layoutThemeSubject$ = this.layoutThemeSubject.asObservable();
    this.subdomainSubject$ = this.subdomainSubject.asObservable();
    this.getWindowTheme();
    
  }

  login(params = {}): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(params).pipe(
      map((auth: AuthModel) => {
        if (auth.is_account_expired) {
          this.router.navigate(['/'+auth.account+'/expired-installation']);
          return false;
        }
        const result = this.setAuthFromLocalStorage(auth);
         //SK on 30/09/2021
         this.setloginAttemptCount(auth.account);
         //SK on 30/09/2021
        localStorage.setItem('username', params['user_name']);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    const rtoken = localStorage.getItem('refresh_token');
    const token = localStorage.getItem('token');
    return this.authHttpService.logout(rtoken, token).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        return of(err);
      }),
    );
  }
  ssologout(account, userId) {
    const rtoken = localStorage.getItem('refresh_token');
    const token = localStorage.getItem('token');
    return this.authHttpService.ssologout(rtoken, token, account, userId).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        return of(err);
      }),
    );
  }
  getAccountType(params) {
    return this.authHttpService.getAccount(params);
  }

  getvalidatelogin(params) {
    return this.authHttpService.getvalidatelogin(params);
  }

  getUserByToken(): Observable<UserModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.token) {
      return of(undefined);
    }
    this.isLoadingSubject.next(true);
    const username = localStorage.getItem('username');
    let userlist =  JSON.parse(localStorage.getItem('userLocalStorageToken'));
    if(userlist) {
      return this.getUserFromLocalStorage(userlist).pipe(
        map((user: UserModel) => {
          if (user) {
            this.currentUserSubject = new BehaviorSubject<UserModel>(user);
            return user;
          }
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
    } else {
      if(username){
        return this.authHttpService.getUserByToken(auth.token, username).pipe(
        map((user: UserModel) => {
          if (user) {
            this.currentUserSubject = new BehaviorSubject<UserModel>(user);
            return user;
          }
      }),
      finalize(() => this.isLoadingSubject.next(false))
      );
     } else {
      this.removeItem();
     }
    }
  }
  getUserFromLocalStorage(userInfo: any): Observable<UserModel> { 
    return of(userInfo);
  }
  getToken(): string {
    return localStorage.getItem('token');
  }

  getTokenExpirationDate(token: string): Date {
    let decoded: any
    try {
      decoded = jwt_decode(token);
      // valid token format
    } catch (error) {
      // invalid token format
      return null;
    }

    if (decoded.exp === undefined) {
      return null;
    }

    const date = new Date(decoded.exp * 1000);
    // date.setUTCSeconds(decoded.exp);
    return date;
  }


  isTokenExpired(token: string): boolean {
    if (!token) {
      token = this.getToken();
    }

    if (!token) {
      return true;
    }

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) {
      return false;
    }

    return date.valueOf() > new Date().valueOf();
  }

  getUserById(token, username) {
    return this.authHttpService.getUserByToken(token, username).pipe(
      mergeMap((user) => {
        return of(user);
      })
    );
  }

  getUserAttributes(token, username) {
    return this.authHttpService.getUserAttributes(token, username).pipe(
      mergeMap((user) => {
        return of(user);
      })
    );
  }

  registration(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createUser(user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      switchMap(() => this.login(user)),
      catchError((err) => {
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(params): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(params)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }


  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.token) {
      localStorage.setItem('token', auth.token);
      localStorage.setItem('refresh_token', auth.refresh_token);
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  // catchError is removed and added mergeMap as in other place to catch error//
  changePassword(name, token, params) {
    return this.authHttpService.changePassword(name, token, params).pipe(
      mergeMap((user) => {
        return of(user);
      })
      );
  }

  linkuser(params) {
    return this.authHttpService.linkuser(params).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        return of(err);
      }),
    );
  }
  
  createPassword(params) {
    return this.authHttpService.createPassword(params).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        return of(err);
      }),
    );
  }
  updatePassword(params) {
    return this.authHttpService.updatePassword(params).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        return of(err);
      }),
    );
  }

  resendInvitation(name, token) {
    return this.authHttpService.resendInvitation(name, token).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        return of(err);
      }),
    );
  }

  private getAuthFromLocalStorage(): AuthModel {
    try {
      const authData = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken)
      );
      return authData;
    } catch (error) {
      return undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  removeItem(param?) {
    let subd = this.getSubdomainFromUrl();
    const identityprovider = localStorage.getItem('identityprovider');
    const loginAttemptCount = localStorage.getItem('loginAttemptCount');
    if(identityprovider){
      this.ssologout(subd, identityprovider).subscribe(res => {
        localStorage.clear();
        localStorage.setItem("subdomain", subd.toLowerCase());
        localStorage.setItem('loginAttemptCount', loginAttemptCount);
        location.href = res.saml_url;
      }, error => {
        localStorage.clear();
        localStorage.setItem("subdomain", subd.toLowerCase());
        localStorage.setItem('loginAttemptCount', loginAttemptCount);
        location.pathname = '/org/login';
        
        document.location.reload();
      });
    }else if(param) {
      localStorage.clear();
      localStorage.setItem("subdomain", param.toLowerCase());
      localStorage.setItem('loginAttemptCount', loginAttemptCount);
    } else {
      this.logout().subscribe(res => {
        localStorage.clear();
        localStorage.setItem("subdomain", subd.toLowerCase());
        localStorage.setItem('loginAttemptCount', loginAttemptCount);
        location.pathname = '/org/login';
        
        document.location.reload();
      }, error => {
        localStorage.clear();
        localStorage.setItem("subdomain", subd.toLowerCase());
        localStorage.setItem('loginAttemptCount', loginAttemptCount);
        location.pathname = '/org/login';
        
        document.location.reload();
      });
   }
  }

  logoutFromAuthGaurd() {
    let subd = this.getSubdomainFromUrl();
    const apiUrl = localStorage.getItem('apiUrl');
    const loginAttemptCount = localStorage.getItem('loginAttemptCount');
    localStorage.clear();
    localStorage.setItem("subdomain", subd.toLowerCase());
    localStorage.setItem('loginAttemptCount', loginAttemptCount);
    this.router.navigate(['/org/login']);
  }
  //SK on 30/09/2021
  setloginAttemptCount(subdomain) {
    let params : any = [];
    let loginAttemptCount = localStorage.getItem('loginAttemptCount');
    if(loginAttemptCount != null) {
    const loginAttemptCount_l = JSON.parse(loginAttemptCount);
    if(Array.isArray(loginAttemptCount_l)) {
    loginAttemptCount_l?.forEach(res => {
        if(res.account == subdomain){
          params.push({ account:res.account, count:  parseInt(res.count) + 1 });
        }else if(res.account != subdomain && !res.account.includes[subdomain]){
          params.push({ account:res.account, count: res.count});
        } else {
          params.push({ account:subdomain, count:  1});
        }
      });
    }else {
      localStorage.removeItem('loginAttemptCount');
      params.push({ account:subdomain, count:  1});
    }
    }
    let index =  params.findIndex((data)=>data.account == subdomain);
    if(index == -1 ) {
        params.push({ account:subdomain, count:  1});
    }
  localStorage.setItem('loginAttemptCount', JSON.stringify(params));
}
  //SK on 30/09/2021
  // get Theme from windows
  public getWindowTheme() {
    let newColorScheme = '';
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
      newColorScheme = e.matches ? "dark-theme" : "light-theme";
      this.layoutThemeSubject.next(newColorScheme);
      localStorage.setItem('currentTheme', newColorScheme);
    });
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      newColorScheme = "dark-theme";
    } else {
      newColorScheme = "light-theme";
    }
    if (!localStorage.getItem('currentTheme')) {
      localStorage.setItem('currentTheme', 'light-theme');
    }
    // localStorage.getItem('currentTheme')? '' : localStorage.setItem('currentTheme', 'light-theme');
    this.layoutThemeSubject.next(newColorScheme);
    return newColorScheme;
  }

  getSubdomainFromUrl() {
    const hostUrl = window.location.pathname;
    const urlHost = hostUrl.split('/');
    let accountKey = "";
    if (urlHost.length > 0) {
      accountKey = urlHost[1];
    }
    return accountKey;
  }
}
