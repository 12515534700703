import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../users/Auth/_services/auth.service';
declare var window: any;

@Injectable({
  providedIn: 'root',
})

export class AppInitService {

  constructor(
    private authService: AuthService
  ) { }

  public init() {
    return from(
      fetch('assets/app-config.json').then(function (response) {
        return response.json();
      })
    ).pipe(
      switchMap((config) => {
        window.config = config.apiUrl;
        window.env = config.environment;
        return new Promise((resolve) => {
          this.authService.getUserByToken().subscribe().add(resolve);
        });
      }));
  }
}
