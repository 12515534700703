import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthModel } from '../Auth/_models/auth.model';
import { UserModel } from '../Auth/_models/user.model';
import { AuthHTTPService } from '../Auth/_services/auth-http';
import { AuthService } from '../Auth/_services/auth.service';
@Injectable({
  providedIn: 'root'
})
export class UserstorageService {

  constructor(  private authHttpService: AuthHTTPService, private authService :AuthService) 
  { }

  getuserstorage(){
    const userstorage = localStorage.getItem('userLocalStorageToken');
    if(userstorage && userstorage != "null" && userstorage != "\"null\"") {
      return userstorage;
    } else {
     this.authService.logoutFromAuthGaurd();
    }
  }
  getToken() {
    const localtoken = localStorage.getItem('token');
    if(localtoken && localtoken != "null" && localtoken != "\"null\"") {
      return localtoken;
    } else {
     this.authService.logoutFromAuthGaurd();
    }
  }
}
