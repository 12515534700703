<div>
    <div class="row">
        <h6 class="col-sm-12" style="justify-content:center;text-align: center;">Something went wrong. <br> Please try again
            later.</h6>
        <!-- <button class="text-center" mat-button (click)="redirectToLogin()" color="primary">Redirect to Login page</button> -->
        <div class=" col-sm-12 notification-button d-flex text-right mt-5">
            <div class="text-right">
                <button class="btn-upper  bt-border" mat-raised-button (click)="Retry()">Retry</button>
            </div>
        </div>
    </div>
</div>