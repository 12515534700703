import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor( private authService: AuthService,  private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      let accName = this.getSubdomain();
      let urlAccName = this.getSubdomainFromUrl();
      if (accName) { //Check account name is valid and page url is exist
        if (urlAccName.toLowerCase() != accName.toLowerCase()) {
          location.pathname = '/' + accName + '/dashboard';
        }
        const hostUrl = window.location.pathname;
        const urlHost = hostUrl.split('/');
        if (urlHost.length > 2) {
          if (urlHost[2].length == 1) {
            const originalRequestUrl = state.url;
            if (this.isOldUrl(originalRequestUrl)) {
              const res = this.getRedirectUrl(originalRequestUrl, urlHost[2])
              location.pathname = res ? res : '/error/404';
            }
          } else if (route.routeConfig.path === '**') {
            location.pathname = '/error/404';
          }
          else {
            let pageUrl = urlHost.length > 2 ? urlHost[2] : '';
            if (!pageUrl) {
              location.pathname = '/' + accName + '/dashboard';
            }
          }
        } else {
          location.pathname = '/' + accName + '/dashboard';
        }
      }
      return true;
    }
    this.authService.logoutFromAuthGaurd()
    return false;
  }

  isOldUrl(url: string): boolean {
    return true;
}

getRedirectUrl(url: string, oldURL: string): string {
  let redirectUrl;
    if(oldURL === 'i') {
      redirectUrl = url.replace('/i/','/service-status/incidents/detail/');
    }
    return redirectUrl;
}
  getSubdomain() {
    const loginDt = JSON.parse(localStorage.getItem('token-key'));
    let accountKey = loginDt?.account? loginDt?.account : '';
    // const hostUrl = window.location.pathname;
    // const urlHost = hostUrl.split('/');
    // // let accountKey = "";
    // if (urlHost.length > 0) {
    //   accountKey = urlHost[1];
    // }
    return accountKey;
  }

  getSubdomainFromUrl() {
    const hostUrl = window.location.pathname;
    const urlHost = hostUrl.split('/');
    let accountKey = "";
    if (urlHost.length > 0) {
      accountKey = urlHost[1];
    }
    return accountKey;
  }

}
