import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class WorkflowActionService {

  constructor(private http: HttpClient) { }

  getAllActions(token, workflowID) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });

    return this.http.get<any>(`${environment.apiUrl}/workflows/${workflowID}/actions`, {
      headers: httpHeaders
    });
  }

  getWorkflowActionByID(token, workflowTypeID) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/app/workflow_types/${workflowTypeID}/workflow_actions`, {
      headers: httpHeaders
    });
  }

  getWorkFlowByID(token, workflowID) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/workflows/${workflowID}`, {
      headers: httpHeaders
    });
  }

  getWebMethod(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/app/workflows/web_methods`, {
      headers: httpHeaders
    });
  }

  getEscalationPolicies(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/app/escalation_policies`, {
      headers: httpHeaders
    });
  }

  getUsers(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/app/users`, {
      headers: httpHeaders
    });
  }

  getGroups(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/app/groups`, {
      headers: httpHeaders
    });
  }

  getOutBoundAction(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/app/outbound_actions`, {
      headers: httpHeaders
    });
  }

  addWorkFlowAction(token, workflowsID, params): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/workflows/${workflowsID}/actions`, params, {
      headers: httpHeaders,
    });
  }

  updateWorkFlowAction(token, workflowsID, actionID, params): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.put<any>(`${environment.apiUrl}/workflows/${workflowsID}/actions/${actionID}`, params, {
      headers: httpHeaders,
    });
  }

  deleteWorkflowAction(token, workflowID, actionID): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.delete<any>(`${environment.apiUrl}/workflows/${workflowID}/actions/${actionID}`, {
      headers: httpHeaders,
    });
  }
}


