import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../../../environments/environment';
import { UserModel } from '../../_models/user.models';
import { AppHelper } from '../../../../_helpers';
// const environment.apiUrl = `${AppHelper.getApiBaseUrl()}/users`;

@Injectable({
  providedIn: 'root',
})
export class UserHTTPService {
  constructor(private http: HttpClient) { }

  getAllUserList(token, roles?, search?, offset?, sortBy?, direction?, rolesBy?) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let param = new HttpParams();
    param = param.append('include', 'roles');
    param = param.append('limit', '25');
    if (roles)
      param = param.append('role', roles);
    if (search)
      param = param.append('search', search);
    if (offset)
      param = param.append('offset', offset);
    if (sortBy)
      param = param.append('sortBy', sortBy);
    if (direction)
      param = param.append('sortDir', direction);
    if (rolesBy)
      param = param.append('include', rolesBy);
    return this.http.get<UserModel>(`${environment.apiUrl}/users`, {
      headers: httpHeaders, params: param
    });
  }
  getAllUserListWithoutparam(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    
    return this.http.get<any>(`${environment.apiUrl}/app/users`, {
      headers: httpHeaders
    });
  }
  deleteUser(name, token): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.delete<UserModel>(`${environment.apiUrl}/users/${name}`, {
      headers: httpHeaders,
    });
  }

  getUserTypes(token) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `${token}`
    });
    return this.http.get<any>(`${environment.apiUrl}/app/user_types`, { headers: httpHeaders });
  }

  getUserRoles(token) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `${token}`
    });
    return this.http.get<any>(`${environment.apiUrl}/app/roles`, { headers: httpHeaders });
  }

  getUserById(token, userId) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `${token}`
    });
    return this.http.get<any>(`${environment.apiUrl}/users/${userId}`, { headers: httpHeaders });
  }

  usersExport(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
      'Content-type': 'text/csv'
    });
    return this.http.get(`${environment.apiUrl}/users/export`, {
      responseType: 'arraybuffer', headers: httpHeaders
    });
  }

  addUserList(paeams = {}, token): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/users`, paeams, {
      headers: httpHeaders,
    });
  }

  importUsers(token, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/users/import`, params, {
      headers: httpHeaders, reportProgress: true, observe: 'events'
    });
  }

  //< Start Ebison  Added on 18/11/2021 //
  getLimit(token,params) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/app/limits/${params}`, {
      headers: httpHeaders,
    });
  }
  // Ebison  Added on 18/11/2021  End >//

  usersTemplateExport(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
      'Content-type': 'text/csv'
    });
    return this.http.get(`${environment.apiUrl}/users/export/template`, {
      responseType: 'arraybuffer', headers: httpHeaders
    });
  }
}
