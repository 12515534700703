import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../../../environments/environment';
import { first } from "rxjs/operators";
import { AppHelper } from '../../../../_helpers';
import { UserModel } from '../../../user-list/_models/user.models';
// const API_USERS_URL = `${AppHelper.getApiBaseUrl()}/users`;

@Injectable({
  providedIn: 'root',
})
export class ContactMethodHTTPService {
  constructor(private http: HttpClient) { }


  getUserCmAdd(paramsId, token, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/users/${paramsId}/contact_methods`, params, {
      headers: httpHeaders,
    });
  }



  getUserCmUpdate(paramsId, token, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.put<any>(`${environment.apiUrl}/users/${paramsId}/contact_methods/${params['contact_method_name']}`, params, {
      headers: httpHeaders,
    });
  }

  deletecontactMethod(name, token, userid): Observable<any> {

    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.delete<UserModel>(`${environment.apiUrl}/users/${userid}/contact_methods/${name}`, {
      headers: httpHeaders,
    });
  }


  getnotificationCM(name, token, userid): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<UserModel>(`${environment.apiUrl}/users/${userid}/contact_methods/${name}/notification_times`, {
      headers: httpHeaders,
    });
  }

  updateNotificationCM(name, token, userid, params = {}, id) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.put<UserModel>(`${environment.apiUrl}/users/${userid}/contact_methods/${name}/notification_times/${id}`, params, {
      headers: httpHeaders,
    });
  }

  createNotificationCM(name, token, userid, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/users/${userid}/contact_methods/${name}/notification_times`, params, {
      headers: httpHeaders,
    });
  }

  addnotificationCM(name, token, userid, params): Observable<any> {

    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<UserModel>(`${environment.apiUrl}/users/${userid}/contact_methods/${name}/notification_times`, params, {
      headers: httpHeaders,
    });
  }

  
  deleteTimes(name, token, user, id): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.delete<any>(`${environment.apiUrl}/users/${user}/contact_methods/${name}/notification_times/${id}`, { headers: httpHeaders });
  }

  getUserContactMethodNames(token): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/app/user_contact_methods`, {
      headers: httpHeaders
    });
  }

  getUserCM(paramsId, token): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/users/${paramsId}/contact_methods`, {
      headers: httpHeaders,
    });
  }

}