import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../../../environments/environment';
import { AppHelper } from '../../../../_helpers';
import { UserModel } from '../../../user-list/_models/user.models';
// const environment.apiUrl = `${AppHelper.getApiBaseUrl()}/users`;

@Injectable({
  providedIn: 'root',
})
export class ScheduleHTTPService {
  constructor(private http: HttpClient) { }

  getUserSchedule(token, username, startDate?, endDate?): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let param = new HttpParams();
    if (startDate)
      param = param.append('startDate', startDate);
    if (endDate)
      param = param.append('endDate', endDate);
    return this.http.get<UserModel>(`${environment.apiUrl}/users/${username}/schedules`, {
      headers: httpHeaders, params: param
    });
  }

}
