import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppHelper } from '../../../_helpers';
import { Router } from '@angular/router';
// RxJS
import { Observable, of, BehaviorSubject } from 'rxjs';
import { mergeMap, map, catchError, finalize } from 'rxjs/operators';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../../_base/curd';
import { environment } from '../../../../../../environments/environment';
import { UserProfileHTTPService } from './user-profile-http/user-profile-http.services';

const API_BASE_URL = `${AppHelper.getApiBaseUrl()}`;


@Injectable()
export class UserProfileServices {
  private isLoadingSubject: BehaviorSubject<boolean>;

  lastFilter$: BehaviorSubject<QueryParamsModel> = new BehaviorSubject(new QueryParamsModel({}, 'asc', '', 0, 10));
  constructor(
    private serProfileHTTPService: UserProfileHTTPService,
  ) { }

  editUser(name, token, params) {
    return this.serProfileHTTPService.editUser(name, token, params).pipe(
      map((res) => {
        return res;
      })
    );
  }

  timeZone(token) {
    return this.serProfileHTTPService.timeZone(token).pipe(
      mergeMap((res) => {
        return of(res);
      })
    );
  }

  locale(token) {
    return this.serProfileHTTPService.locale(token).pipe(
      mergeMap((res) => {
        return of(res);
      })
    );
  }


}
