import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../../../environments/environment';
import { AppHelper } from '../../../../_helpers';
import { UserModel } from '../../../user-list/_models/user.models';
// const API_USERS_URL = `${AppHelper.getApiBaseUrl()}/users`;

@Injectable({
  providedIn: 'root',
})
export class AttributeHTTPService {
  constructor(private http: HttpClient) { }

  getUserByToken(token, username): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<UserModel>(`${environment.apiUrl}/users/${username}`, {
      headers: httpHeaders,
    });
  }

  updateAttributes(userId, token, params): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.put<any>(`${environment.apiUrl}/users/${userId}/user_attributes`, params, {
      headers: httpHeaders,
    });
  }

  getUserAtrribute(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<UserModel>(`${environment.apiUrl}/user_attributes`, {
      headers: httpHeaders,
    });
  }

}
