export const DynamicAsideMenuConfig = {
  items: [
    {
      title: 'Dashboards',
      alignment: 'left',
      page: '/dashboard',
      translate: 'MENU.DASHBOARD',
    },
    {
      title: 'Alerts',
      alignment: 'left',
      page: '/alerts',
    },
    {
      title: 'Reports',
      alignment: 'left',
      page: '/reports',
    },
    {
      title: 'Users',
      bullet: 'dot',
      page: '/users',
      icon: 'flaticon-interface-7',
      submenu: [
        {
          title: 'Vishwa Trivedi',
          page: '/users/vishwatrivedi',
        },
      ]
    },
    {
      title: 'Configuration',
      alignment: 'left',
      page: '/configuration',
    }
  ]
};
