import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppHelper } from '../../../_helpers';
import { Router } from '@angular/router';
// RxJS
import { Observable, of, BehaviorSubject } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../../_base/curd';
import { UserModel } from '../_models/user.models';
import { environment } from '../../../../../../environments/environment';
import { UserHTTPService } from './user-http/user-http.services';

const API_BASE_URL = `${AppHelper.getApiBaseUrl()}`;


@Injectable()
export class UserServices {
  addAnother = new EventEmitter<string>();
  lastFilter$: BehaviorSubject<QueryParamsModel> = new BehaviorSubject(new QueryParamsModel({}, 'asc', '', 0, 10));
  constructor(
    private userHttpService: UserHTTPService,
    private httpUtils: HttpUtilsService,
    private router: Router
  ) { }

  getAllUserList(token, roles?, search?, offset?, sortBy?, direction?, rolesBy?) {
    return this.userHttpService.getAllUserList(token, roles, search, offset, sortBy, direction, rolesBy).pipe(
      mergeMap((res) => {
        const data = res['users'];
        return of(data);
      })
    );
  }
  getAllUserListWithOutParam(token) {
    return this.userHttpService.getAllUserListWithoutparam(token).pipe(
      mergeMap((res) => {
        const data = res;
        return of(data);
      })
    );
  }
  deleteUser(name, token) {
    return this.userHttpService.deleteUser(name, token).pipe(
      map((user) => {
        return of(user);
      }),
      catchError((err) => {
        return of(err);
      }),
    );
  }

  getUserTypes(token) {
    return this.userHttpService.getUserTypes(token).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        return of(err);
      }),
    );
  }

  getUserRoles(token) {
    return this.userHttpService.getUserRoles(token).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  getUserById(token, userId) {
    return this.userHttpService.getUserById(token, userId).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  usersExport(token) {
    return this.userHttpService.usersExport(token).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  addUserList(params = {}, token) {
    return this.userHttpService.addUserList(params, token).pipe(
      mergeMap((res) => {
        const data = res;
        return of(data);
      }),
    );
  }

  importUser(token, param) {
    return this.userHttpService.importUsers(token, param).pipe(
      mergeMap((res) => {
        const data = res;
        return of(data);
      }),
    );
  }
  //< Start Ebison  Added on 18/11/2021 //
  getLimit(token,param){
    return this.userHttpService.getLimit(token, param).pipe(
      mergeMap((res) => {
        const data = res;
        return of(data);
      }),
    );
  }
  // Ebison  Added on 18/11/2021  End >//

}
