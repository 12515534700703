import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../../../environments/environment';
import { AppHelper } from '../../../../_helpers';
import { UserModel } from '../../../user-list/_models/user.models';
// const API_USERS_URL = `${AppHelper.getApiBaseUrl()}/users`;

@Injectable({
  providedIn: 'root',
})
export class OutOfOfficeHTTPService {
  constructor(private http: HttpClient) { }

  AddOOf(name, token, params = {}) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `${token}`
    });
    return this.http.post(`${environment.apiUrl}/users/${name}/out_of_office`, params, { headers: httpHeaders });
  }

  getUserOutbound(token, username): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/users/${username}/out_of_office`, {
      headers: httpHeaders,
    });
  }

  deleteOOfO(id, outofofficeid, token): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.delete<any>(`${environment.apiUrl}/users/${id}/out_of_office/${outofofficeid}`, { headers: httpHeaders });
  }

  getGroupsById(id, token) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `${token}`
    });
    return this.http.get<any>(`${environment.apiUrl}/users/${id}/groups`, { headers: httpHeaders });
  }

}
