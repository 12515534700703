import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppHelper } from '../../../_helpers';
import { Router } from '@angular/router'
// RxJS
import { Observable, of, BehaviorSubject } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../../_base/curd';
import { environment } from '../../../../../../environments/environment';
import { ContactMethodHTTPService } from './contact-method-http/contact-method-http.services';

const API_BASE_URL = `${AppHelper.getApiBaseUrl()}`;


@Injectable()
export class ContactMethodServices {
  lastFilter$: BehaviorSubject<QueryParamsModel> = new BehaviorSubject(new QueryParamsModel({}, 'asc', '', 0, 10));
  constructor(
    private contactMethodHTTPService: ContactMethodHTTPService,
  ) { }

  getUserCmAdd(paramsId, token, params = {}) {
    return this.contactMethodHTTPService.getUserCmAdd(paramsId, token, params).pipe(
      mergeMap((user) => {
        return of(user);
      }),
    );
  }

  getUserCmUpdate(paramsId, token, params = {}) {
    return this.contactMethodHTTPService.getUserCmUpdate(paramsId, token, params).pipe(
      mergeMap((user) => {
        return of(user);
      }),
    );
  }

  deletecontactMethod(name, token, userid) {
    return this.contactMethodHTTPService.deletecontactMethod(name, token, userid).pipe(
      mergeMap((user) => {
        return of(user);
      })
    );
  }

  getnotificationCM(name, token, userid) {
    return this.contactMethodHTTPService.getnotificationCM(name, token, userid).pipe(
      mergeMap((user) => {
        return of(user);
      })
    );
  }

  createNotificationCM(name, token, userid, params = {}) {
    return this.contactMethodHTTPService.createNotificationCM(name, token, userid, params).pipe(
      mergeMap((user) => {
        return of(user);
      })
    );
  }

  updateNotificationCM(name, token, userid, params = {}, id) {
    return this.contactMethodHTTPService.updateNotificationCM(name, token, userid, params, id).pipe(
      mergeMap((user) => {
        return of(user);
      })
    );
  }

  addnotificationCM(name, token, userid, params) {
    return this.contactMethodHTTPService.addnotificationCM(name, token, userid, params).pipe(
      mergeMap((user) => {
        return of(user);
      })
    );
  }

  deleteTimes(name, token, user, id) {
    return this.contactMethodHTTPService.deleteTimes(name, token, user, id).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        return of(err);
      }),
    );
  }

  getUserContactMethodNames(token) {
    return this.contactMethodHTTPService.getUserContactMethodNames(token).pipe(
      mergeMap((res) => {
        return of(res);
      })
    );
  }

  getUserCm(paramsId, token) {
    return this.contactMethodHTTPService.getUserCM(paramsId, token).pipe(
      mergeMap((user) => {
        return of(user);
      }),
    );
  }
}