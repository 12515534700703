// Angular
import { Injectable } from '@angular/core';
import { HttpParams, HttpHeaders } from '@angular/common/http';
// Lodash
import * as _ from 'lodash';
// CRUD
import { QueryResultsModel } from '../models/query-models/query-results.model';
import { QueryParamsModel } from '../models/query-models/query-params.model';
import { HttpExtenstionsModel } from '../../curd/models/http-extentsions-model';

@Injectable()
export class HttpUtilsService {
/**
 * Prepare query http params
 * @param queryParams: QueryParamsModel
 */
getFindHTTPParams(queryParams): HttpParams {
const params = new HttpParams()
.set('lastNamefilter', queryParams.filter)
.set('sortOrder', queryParams.sortOrder)
.set('sortField', queryParams.sortField)
.set('pageNumber', queryParams.pageNumber.toString())
.set('pageSize', queryParams.pageSize.toString());

return params;
}

/**
 * get standard content-type
 */
getHTTPHeaders(): HttpHeaders {
const result = new HttpHeaders();
result.set('Content-Type', 'application/json');
return result;
}

baseFilter(entities: any[], queryParams: QueryParamsModel, filtrationFields: string[] = []): QueryResultsModel {
const httpExtention = new HttpExtenstionsModel();
return httpExtention.baseFilter(entities, queryParams, filtrationFields);
}

sortArray(incomingArray: any[], sortField: string = '', sortOrder: string = 'asc'): any[] {
const httpExtention = new HttpExtenstionsModel();
return httpExtention.sortArray(incomingArray, sortField, sortOrder);
}

searchInArray(incomingArray: any[], queryObj: any, filtrationFields: string[] = []): any[] {
const httpExtention = new HttpExtenstionsModel();
return httpExtention.searchInArray(incomingArray, queryObj, filtrationFields);
}
}
