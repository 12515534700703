import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirstLetterPipe } from './pipes/first-letter.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { Limit } from './pipes/limit';
import { Stringlength } from './pipes/length';
import { SimpleSearchPipe } from './pipes/search.pipe';
import { JsonprettyPipe } from './pipes/jsonpretty.pipe';
import { DateparsePipe } from './pipes/dateparse.pipe';
import { AutofocusDirective } from './_base/curd/directive/auto-focus.directive';
import { SafeHtmlPipe } from './pipes/domsanitized.pipe';

@NgModule({
  declarations: [FirstLetterPipe, SafePipe, Limit, SimpleSearchPipe, JsonprettyPipe, DateparsePipe, AutofocusDirective,Stringlength, SafeHtmlPipe],
  imports: [CommonModule],
  exports: [FirstLetterPipe, SafePipe, Limit, SimpleSearchPipe, JsonprettyPipe, DateparsePipe, AutofocusDirective,Stringlength, SafeHtmlPipe],
})
export class CoreModule { }
