import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class GroupAcessService {

  constructor(private http: HttpClient) { }

  getAcessByID(token, groupID): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/groups/${groupID}/access`, {
      headers: httpHeaders
    });
  }

  getAcessAdmin(token, groupID): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/groups/${groupID}/access/admins_available`, {
      headers: httpHeaders
    });
  }

  addAcessAdmin(token, groupID, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/groups/${groupID}/access/admins`, params, {
      headers: httpHeaders,
    });
  }

  deleteAcess(token, groupID, body): Observable<any> {
    const data = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
      body: body
    }
    return this.http.delete<any>(`${environment.apiUrl}/groups/${groupID}/access/admins`, data);
  }

  switchOwner(token, groupID, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.put<any>(`${environment.apiUrl}/groups/${groupID}/access/owner`, params, {
      headers: httpHeaders,
    });
  }
}
