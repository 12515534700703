import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
  NgZone,
  ChangeDetectorRef,
} from '@angular/core';
import { TranslationService } from './modules/i18n/translation.service';
import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as chLang } from './modules/i18n/vocabs/ch';
import { locale as esLang } from './modules/i18n/vocabs/es';
import { locale as jpLang } from './modules/i18n/vocabs/jp';
import { locale as deLang } from './modules/i18n/vocabs/de';
import { locale as frLang } from './modules/i18n/vocabs/fr';
import { SplashScreenService } from './_theme/partials/layout/splash-screen/splash-screen.service';
import { Router, NavigationEnd, ActivationStart, ActivationEnd } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { AuthService } from './_theme/core/users/Auth/_services/auth.service';
import { AuthHTTPService } from './_theme/core/users/Auth/_services/auth-http';
import { environment } from 'src/environments/environment';
import { datadogRum } from '@datadog/browser-rum';
import { LayoutService } from './_theme/core';
import { UserstorageService } from './_theme/core/users/userstorage/userstorage.service';
import { Userpilot } from 'userpilot';


@Component({
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  private unsubscribe: Subscription[] = []; 
  private subscription: Subscription;
  public interval: number;
  private userActivityChangeCallback: ($event) => void;
  public loading = false;
  public isRefreshToken = false;
  public isMouseMove = false;
  subdomainValue: any;
  loggedin_userID: any;
  constructor(
    private translationService: TranslationService,
    private splashScreenService: SplashScreenService,
    private router: Router,
    private authService: AuthService,
    private authHttpService: AuthHTTPService,
    private zone: NgZone,
    private cd: ChangeDetectorRef,
    private layoutService: LayoutService,
    private userservice: UserstorageService,
  ) {
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang
    );
  }

  ngOnInit() {
    this.dataDogInit();
    this.initDoc360();
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.splashScreenService.hide();
        window.scrollTo(0, 0);
        Userpilot.reload();
        var snapshot = this.router.routerState.snapshot.root;
				var nextParams = snapshot.params;
        console.log("reload",event.url);
        // to display back the body content
        // setTimeout(() => {
        //   document.body.classList.add('page-loaded');
        // }, 500);
      }
      if (event instanceof ActivationStart) {
        let path_detail;
        if (event.snapshot?.pathFromRoot?.filter(e => e.routeConfig?.path)[1]?.url[0]?.path == event.snapshot.routeConfig.path) {
          path_detail = '/' + event.snapshot?.pathFromRoot?.filter(e => e.routeConfig?.path)[1]?.url[0]?.path;
        } else if ((event.snapshot?.pathFromRoot?.filter(e => e.routeConfig?.path)[1]?.url[0]?.path != event.snapshot.routeConfig.path) && event.snapshot?.pathFromRoot?.filter(e => e.routeConfig?.path)[1]?.url[0]?.path && event.snapshot.routeConfig.path) {
          path_detail = '/' + event.snapshot?.pathFromRoot?.filter(e => e.routeConfig?.path)[1]?.url[0]?.path + '/' + event.snapshot.routeConfig.path
        }
        if (path_detail) {
          datadogRum.startView({
            name: path_detail
          });
          console.log("path",path_detail);
        }
        else {
          let rt = event.snapshot.root;
          let p = event.snapshot.parent;
          //if (rt.url == p.url) {
          //  let seg = p._urlSegment.segments[1];
            console.log("no path",'/' + p.url.toString());
          //}
          //else {
            console.log("no path",event.snapshot.routeConfig,event.snapshot.root,event.snapshot.parent);
          //}
        }
      }
    });
    this.unsubscribe.push(routerSubscription);

  }
  dataDogInit() {
    Userpilot.initialize('NX-f258bf1c');
    datadogRum.init({
      applicationId: environment.applicationId,
      clientToken: environment.clientToken,
      site: 'datadoghq.com',
      service:'aowebapp',
      env: environment.env,
      // Specify a version number to identify the deployed version of your application in Datadog 
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel:'mask-user-input'
    });

    datadogRum.startSessionReplayRecording();
    let user_storage = localStorage.getItem('userLocalStorageToken');
    if(user_storage) {
      this.loggedin_userID = JSON.parse(user_storage);
      let ddg_user_id = datadogRum.getUser();
      if(this.loggedin_userID.user_id){
        if(ddg_user_id.user_id !== this.loggedin_userID.user_id){
          datadogRum.setUser({id:this.loggedin_userID.user_id})
        }
        let subdomain = this.layoutService.getSubdomain();      
        if(subdomain == '') { 
            subdomain = localStorage.getItem('subdomain');
        }
        Userpilot.identify(
                		this.loggedin_userID.user_id,
                		{
                    			name: this.loggedin_userID.first_name,
                          account: subdomain
                          //company:  
                          //{
                          //    id: subdomain
                          //}
                		}
            		);
      }
    }
  }

  initDoc360() {
    let code = "(function (w,d,s,o,f,js,fjs) { " +
      "w['JS-Widget']=o;w[o] = w[o] || function () {(w[o].q = w[o].q || []).push(arguments)};" +
      "js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];" +
      "js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);" +
    "} (window, document, 'script', 'mw', 'https://cdn.document360.io/static/js/widget.js')); " +
    "mw('init', { apiKey: 'tusYiC7GcSm7yZIK9pDtyDgzjcBm564URYf1VDFzB2g79XYTJBTzXSULrTwLkoO5k6tNJzSQS6GSWDxVgAgHRWsTPbWqMioXYMgxHWNabvBE0Ky5Eu0lkQ1INmngXyB8CzEVzpdk/1XK3HPSwrxEZQ==' }); ";
    let s = document.createElement("script");
    s.type = "text/javascript";
    s.innerHTML = code;
    let body = document.getElementById("kt_body");
    let layout = document.getElementsByTagName('app-layout')[0];
    body.insertBefore(s, layout);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

}

