import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class WorkflowUsageService {

  constructor(private http: HttpClient) { }

  getAllWorkflowsUsage(token, workflowID) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/workflows/${workflowID}/usage`, {
      headers: httpHeaders
    });
  }

  getWorkFlowByID(token, workflowID) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/workflows/${workflowID}`, {
      headers: httpHeaders
    });
  }

  getAllAlertTypes(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/alert_types`, {
      headers: httpHeaders
    });
  }

  addWorkFlow(token, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/workflows`, params, {
      headers: httpHeaders,
    });
  }

  updateWorkFlow(token, params): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.put<any>(`${environment.apiUrl}/workflows/${params['workflow_id']}`, params, {
      headers: httpHeaders,
    });
  }

  deleteWorkflowAction(token, workflowID , actionID): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.delete<any>(`${environment.apiUrl}/workflows/${workflowID}/actions/${actionID}`, {
      headers: httpHeaders,
    });
  }
}


