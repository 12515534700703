import { Injectable, Pipe } from '@angular/core';

@Pipe({
    name: 'stringlength'
})

@Injectable()
export class Stringlength {

    constructor() { }
    transform(value: string,stringLimit: number): any {
        return value?.length > stringLimit ? '...' : ''
    }
}
