import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class WorkflowConditionService {

  constructor(private http: HttpClient) { }

  getAllWorkflowConditions(token, workflowID) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });

    return this.http.get<any>(`${environment.apiUrl}/workflows/${workflowID}/conditions`, {
      headers: httpHeaders
    });
  }

  getConditionsByAlertType(token, workflowTypeID, alertTypeID) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/app/workflow_types/${workflowTypeID}/workflow_conditions/${alertTypeID}`, {
      headers: httpHeaders
    });
  }

  getWFValuesConditionID(token, workflowTypeID, alertTypeID, conditionID) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/app/workflow_types/${workflowTypeID}/workflow_conditions/${alertTypeID}/workflow_values/${conditionID}`, {
      headers: httpHeaders
    });
  }

  addWorkFlowCondition(token, workflowID, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/workflows/${workflowID}/conditions`, params, {
      headers: httpHeaders,
    });
  }

  updateWorkFlowCondition(token, workflowID, conditionID, params): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.put<any>(`${environment.apiUrl}/workflows/${workflowID}/conditions/${conditionID}`, params, {
      headers: httpHeaders,
    });
  }

  deleteWorkflowConditions(token, workflowID, conditionID): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.delete<any>(`${environment.apiUrl}/workflows/${workflowID}/conditions/${conditionID}`, {
      headers: httpHeaders,
    });
  }
}


