import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class GroupsService {

  constructor(private http: HttpClient) { }

  getAllGroupList(token, searchBy?, offset?, sortBy?, direction?) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let param = new HttpParams();
    param = param.append('limit', '25');
    if (searchBy)
      param = param.append('search', searchBy);
    if (offset)
      param = param.append('offset', offset);
    if (sortBy)
      param = param.append('sortBy', sortBy);
    if (direction)
      param = param.append('sortDir', direction);
    return this.http.get<any>(`${environment.apiUrl}/groups`, {
      headers: httpHeaders, params: param
    });
  }

  getGroupByID(token, groupID) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/groups/${groupID}`, {
      headers: httpHeaders
    });
  }

  groupsExport(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
      'Content-type': 'text/csv'
    });
    return this.http.get(`${environment.apiUrl}/groups/export`, {
      responseType: 'arraybuffer', headers: httpHeaders
    });
  }

  groupsMemberExport(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
      'Content-type': 'text/csv'
    });
    return this.http.get(`${environment.apiUrl}/groups/members/export`, {
      responseType: 'arraybuffer', headers: httpHeaders
    });
  }

  getAbilities(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/app/abilities`, {
      headers: httpHeaders
    });
  }

  deleteGroup(token, groupID): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.delete<any>(`${environment.apiUrl}/groups/${groupID}`, {
      headers: httpHeaders,
    });
  }

  editGroup(token, groupID, params) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `${token}`
    });
    return this.http.put(`${environment.apiUrl}/groups/${groupID}`, params, {
      headers: httpHeaders,
    });
  }

  getAllTopicList(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/topics`, {
      headers: httpHeaders
    });
  }

  addGroup(token, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/groups`, params, {
      headers: httpHeaders,
    });
  }

  importGroups(token, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/groups/import`, params, {
      headers: httpHeaders,
    });
  }

  importGroupMembers(token, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/groups/members/import`, params, {
      headers: httpHeaders, reportProgress: true, observe: 'events'
    });
  }

  importGroup(token, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/groups/import`, params, {
      headers: httpHeaders, reportProgress: true, observe: 'events'
    });
  }

  groupsTemplateExport(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
      'Content-type': 'text/csv'
    });
    return this.http.get(`${environment.apiUrl}/groups/export/template`, {
      responseType: 'arraybuffer', headers: httpHeaders
    });
  }

  groupMembersTemplateExport(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
      'Content-type': 'text/csv'
    });
    return this.http.get(`${environment.apiUrl}/groups/members/export/template`, {
      responseType: 'arraybuffer', headers: httpHeaders
    });
  }

  editGroupBasic(token, groupID, params) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `${token}`
    });
    return this.http.put(`${environment.apiUrl}/groups/${groupID}/basic`, params, {
      headers: httpHeaders,
    });
  }

}
