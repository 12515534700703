export const DynamicHeaderMenuConfig = {
  items: [
    {
      title: 'Dashboard',
      alignment: 'left',
      page: '/dashboard',
      translate: 'MENU.DASHBOARD',
      permissions: false
    },
    {
      title: 'Alerts',
      alignment: 'left',
      page: '/alerts',
      permissions: false
    },
    {
      title: 'Configuration',
      alignment: 'left',
      page: '/configuration',
      permissions: false,
      submenu: [
        {
          title: 'Users',
          bullet: 'dot',
          page: '/users',
          icon: 'flaticon-interface-7',
          permissions: false,
        },
        {
          title: 'Groups and Schedules',
          bullet: 'dot',
          page: '/groups-schedules',
          icon: 'flaticon-interface-7',
          permissions: false,
        },
        {
          title: 'Integrations',
          bullet: 'dot',
          page: '/integrations',
          icon: 'flaticon-interface-7',
          permissions: false
        },
        {
          title: 'Call Routing',
          bullet: 'dot',
          page: '/call-routing',
          icon: 'flaticon-interface-7',
          permissions: false
        },
        {
          title: 'Escalation Policies',
          bullet: 'dot',
          page: 'escalation-policies',
          icon: 'flaticon-interface-7',
          permissions: false,
        },
        {
          title: 'Workflows',
          bullet: 'dot',
          page: '/workflows',
          icon: 'flaticon-interface-7',
          permissions: false,
        },
        {
          title: 'Administration',
          bullet: 'dot',
          page: '/administration',
          icon: 'flaticon-interface-7',
          permissions: false,
        },
      ]
    },
    {
      title: 'Service Status',
      alignment: 'left',
      page: '/service-status',
      icon: 'flaticon-interface-7',
      permissions: false
    },
    {
      title: 'Analytics',
      alignment: 'left',
      page: '/reports',
      icon: 'flaticon-interface-7',
      permissions: false,
    },
    // SK on 03/09/21 Code for Subscription and Billing menu
    {​​​​​​​​
      title:'Account Settings',
      alignment:'left',
      page:'/account-settings',
      icon:'flaticon-interface-7',
      permissions: false,
    }​​​​​​​​,
    {​​​​​​​​
      title:'Billing',
      alignment:'left',
      page:'/billing',
      icon:'flaticon-interface-7',
      permissions: false,
    }​​​​​​​​,
    {​​​​​​​​
      title:'Subscription',
      alignment:'left',
      page:'/subscription',
      icon:'flaticon-interface-7',
      permissions: false,
    }​​​​​​​​,
    // SK on 03/09/21
     // SK on 03/09/21 Quick start menu added for page title
     {​​​​​​​​
      title:'Quick Start',
      alignment:'left',
      page:'/quick-start',
      icon:'flaticon-interface-7',
    }​​​​​​​​
    // SK on 03/09/21
  ]
};
