import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../src/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class AdminRolesService {

  constructor(private http: HttpClient) { }

  getAllRoles(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/roles`, {
      headers: httpHeaders
    });
  }

  getAllRolesByID(token, roleName) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/roles/${roleName}`, {
      headers: httpHeaders
    });
  }

  getAllEntitlements(token){
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/app/entitlements`, {
      headers: httpHeaders
    });
  }

  getAllUsersByRole(token, roleName) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/roles/${roleName}/users`, {
      headers: httpHeaders
    });
  }

  addRole(token, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/roles`, params, {
      headers: httpHeaders,
    });
  }

  updateRole(token, roleName, params): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.put<any>(`${environment.apiUrl}/roles/${roleName}`, params, {
      headers: httpHeaders,
    });
  }

  deleteRole(token, roleName): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.delete<any>(`${environment.apiUrl}/roles/${roleName}`, {
      headers: httpHeaders,
    });
  }
// <!-- Ebison on 04-Feb-2022 Add users to roles -->
  addMembersToaRole(token, roleID, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/roles/${roleID}/users`, params, {
      headers: httpHeaders,
    });
  }
  deleteMembersToaRole(token, roleID, params): Observable<any> {
    const data = {
      headers: new HttpHeaders({
        Authorization: `${token}`,
      }),
      body: params
    }
    return this.http.delete<any>(`${environment.apiUrl}/roles/${roleID}/users`,data );
  }
  // <!--  -->
  deleteMemberFromaRole(token,roleID,userId): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.delete<any>(`${environment.apiUrl}/roles/${roleID}/users/${userId}`,{
      headers: httpHeaders,
    });
  }

  getOnlyOneRole(token, roleName, userId) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/role/${roleName}/users/${userId}`, {
      headers: httpHeaders
    });
  }

}


